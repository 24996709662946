var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isMobile
      ? _c("div", { staticClass: "mobile" }, [
          _c("i", { staticClass: "iconfont icondiannaoban" }),
          _c("span", { staticClass: "title" }, [_vm._v("请使用电脑端打开！ ")]),
          _c("span", { staticClass: "content" }, [
            _vm._v(
              " 考生您好！ 此检测页面为电脑设备检测，请在电脑端打开，不要使用手机进行检测。 "
            ),
          ]),
        ])
      : _c(
          "div",
          { staticClass: "common-bg detect" },
          [
            _c("simple-header", { attrs: { hasRight: false } }),
            _c(
              "GlobalDialog",
              {
                attrs: {
                  dialogVisible: _vm.deviceDetectNormal,
                  title: "检测结果",
                },
                on: {
                  "dialog-cancel": function ($event) {
                    _vm.deviceDetectNormal = false
                  },
                },
              },
              [
                _c("div", { staticClass: "detect-result" }, [
                  _c("i", { staticClass: "iconfont iconright-f" }),
                  _c("div", { staticClass: "right" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("考试设备及网络正常"),
                    ]),
                    _c("span", { staticClass: "content" }, [
                      _vm._v("可以正常进入考试系统"),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c(
              "GlobalDialog",
              {
                attrs: {
                  dialogVisible: _vm.showProblem,
                  show_close: false,
                  title: "设备检测常见问题",
                  width: "800",
                },
                on: {
                  "dialog-cancel": function ($event) {
                    _vm.showProblem = false
                  },
                },
              },
              [
                _c("div", { staticClass: "problem-container" }, [
                  _c("h2", { staticStyle: { "text-align": "center" } }, [
                    _vm._v("常见问题回答"),
                  ]),
                  _c("h2", [_vm._v("电脑摄像头问题")]),
                  _c("p", { staticStyle: { "font-weight": "bold" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#cb2a1d",
                        },
                      },
                      [_vm._v("问：")]
                    ),
                    _vm._v("电脑摄像头硬件或驱动有故障，怎么办？"),
                  ]),
                  _c("p", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#5fa4f9",
                        },
                      },
                      [_vm._v("答：")]
                    ),
                    _vm._v(
                      "如果驱动故障，请从官网下载最新版驱动，安装后重启电脑。如果是硬件故障，请更换电脑摄像头，或更换其他的电脑设备。"
                    ),
                  ]),
                  _c("p", { staticStyle: { "font-weight": "bold" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#cb2a1d",
                        },
                      },
                      [_vm._v("问：")]
                    ),
                    _vm._v("电脑摄像头无故障，但就是无法看到图像，怎么办？"),
                  ]),
                  _c("p", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#5fa4f9",
                        },
                      },
                      [_vm._v("答：")]
                    ),
                    _vm._v("通常有四种原因："),
                  ]),
                  _c("p", [
                    _vm._v(
                      " 1、浏览器将摄像头禁用。请查看浏览器地址栏右侧是否有打叉的摄像头图标。解决办法：点击谷歌浏览器右上角三个竖点--设置--隐私设置和安全性--网站设置--摄像头，授予权限，然后Ctrl+F5刷新即可； "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "2、有些品牌电脑的摄像头会有个物理的开关按钮，例如联想电脑，请务必把开关键打开；"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "3、其他浏览器或应用程序占用了摄像头，请重启电脑即可解决；"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "4、软件冲突。请关闭360魔法摄像头之类的工具软件，重启电脑即可解决；"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "Tips：1、通过重启电脑基本都能解决问题；2、如果仍然有故障，请务必更换其他的电脑设备。"
                    ),
                  ]),
                  _c("p", { staticStyle: { "font-weight": "bold" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#cb2a1d",
                        },
                      },
                      [_vm._v("问：")]
                    ),
                    _vm._v("电脑摄像头显示后置画面，如何切换到前置画面？"),
                  ]),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#5fa4f9",
                        },
                      },
                      [_vm._v("答：")]
                    ),
                    _vm._v("可进行如下尝试： "),
                    _c("p", [
                      _vm._v(
                        "1、点击谷歌浏览器右上角三个点--进入设置--安全和隐私设置--网站设置--摄像头--页面最上方下拉选择另外一个摄像头，然后刷新页面即可看到前置画面；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "2、如果上述步骤操作后仍不行，可尝试如下操作，首先打开电脑的设置--设备--蓝牙和其他设备--设备和打印机，弹出窗口，找到前置摄像头，右键选择属性，将该摄像头设置为默认摄像头，如果到设备和打印机这步没自动弹出弹窗，请在空白处右键，选择设备管理器，进入后找到摄像头；"
                      ),
                    ]),
                    _c("p", [
                      _vm._v("3、如果上述操作后仍不行，请更换其他的电脑设备。"),
                    ]),
                  ]),
                  _c("p", { staticStyle: { "font-weight": "bold" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#cb2a1d",
                        },
                      },
                      [_vm._v("问：")]
                    ),
                    _vm._v("电脑摄像头对准哪里算是合规？"),
                  ]),
                  _c("p", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#5fa4f9",
                        },
                      },
                      [_vm._v("答：")]
                    ),
                    _vm._v(
                      "请调整摄像头角度，将头像放置在画面中间位置，能清晰看清脸部，确保在整个答题过程中脸部正对摄像头。背景画面内不要有强光源，如有强光照射窗户，请拉上窗帘。 "
                    ),
                  ]),
                  _c("p", { staticStyle: { "font-weight": "bold" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#cb2a1d",
                        },
                      },
                      [_vm._v("问：")]
                    ),
                    _vm._v("还有其他问题，有技术支持电话吗？"),
                  ]),
                  _c("p", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#5fa4f9",
                        },
                      },
                      [_vm._v("答：")]
                    ),
                    _vm._v(
                      "电脑摄像头正常开启对考试非常重要，为了保证考试的公平性，考官会全程视频监控考生作答的情况，因此请务必保证摄像头可以正常使用。如果还有其他技术问题，"
                    ),
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("请致电：010-86623456"),
                    ]),
                    _vm._v("，技术人员将为您解答。 "),
                  ]),
                  _c("h2", [_vm._v("麦克风&扬声器问题")]),
                  _c("p", { staticStyle: { "font-weight": "bold" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#cb2a1d",
                        },
                      },
                      [_vm._v("问：")]
                    ),
                    _vm._v("电脑是台式机，没有麦克风和扬声器设备，怎么办？ "),
                  ]),
                  _c("p", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#5fa4f9",
                        },
                      },
                      [_vm._v("答：")]
                    ),
                    _vm._v(
                      "建议自备外接的电脑麦克风和扬声器设备，确保可以正常使用。如果确实没有这两个设备，则可以忽略，确保电脑摄像头正常使用即可，对考试成绩没有影响。 "
                    ),
                  ]),
                  _c("p", { staticStyle: { "font-weight": "bold" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#cb2a1d",
                        },
                      },
                      [_vm._v("问：")]
                    ),
                    _vm._v("电脑麦克风和扬声器设备故障，怎么办？ "),
                  ]),
                  _c("p", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#5fa4f9",
                        },
                      },
                      [_vm._v("答：")]
                    ),
                    _vm._v(
                      "请在电脑设置里找到此设备，通过更新驱动或重启电脑来解决故障。如果故障无法解决，则可以忽略，确保电脑摄像头正常使用即可，对考试成绩没有影响。 "
                    ),
                  ]),
                  _c("h2", [_vm._v("网络问题")]),
                  _c("p", { staticStyle: { "font-weight": "bold" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#cb2a1d",
                        },
                      },
                      [_vm._v("问：")]
                    ),
                    _vm._v("系统检测网络较慢，比较卡顿，怎么办？ "),
                  ]),
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "bold",
                          "font-size": "18px",
                          color: "#5fa4f9",
                        },
                      },
                      [_vm._v("答：")]
                    ),
                    _vm._v("建议两种方式解决： "),
                    _c("p", [
                      _vm._v("1、更换其他Wi-Fi信号强的网络，确保网络畅通；"),
                    ]),
                    _c("p", [
                      _vm._v(
                        "2、使用4G手机分享Wifi热点上网。通过电脑的无线设备连接手机的Wifi热点上网，正常的网速都比较快。"
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("在线考试 设备检测"),
              ]),
              _c("div", { staticClass: "subtitle" }, [
                _vm._v(" 为保证在线考试顺利进行，请按系统提示进行设备检测 "),
              ]),
              _c(
                "span",
                {
                  staticClass: "problem",
                  on: {
                    click: function ($event) {
                      _vm.showProblem = true
                    },
                  },
                },
                [
                  _c("i", { staticClass: "iconfont iconbangzhu" }),
                  _vm._v(" 检测有故障？查看解决办法！"),
                ]
              ),
              _c("div", { staticClass: "content" }, [_c("device-detect")], 1),
            ]),
            _c("simple-footer"),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }