<!--
 * @Author: Libra
 * @Date: 2021-08-05 10:33:15
 * @LastEditTime: 2024-05-22 13:50:45
 * @LastEditors: Libra
 * @Description:设备检测页面
 * @FilePath: /stone-exam-ui/src/views/common/DeviceDetection.vue
-->
<template>
  <div>
    <div class="mobile" v-if="isMobile">
      <i class="iconfont icondiannaoban"></i>
      <span class="title">请使用电脑端打开！ </span>
      <span class="content">
        考生您好！
        此检测页面为电脑设备检测，请在电脑端打开，不要使用手机进行检测。
      </span>
    </div>
    <div v-else class="common-bg detect">
      <simple-header :hasRight="false" />
      <GlobalDialog
        :dialogVisible="deviceDetectNormal"
        @dialog-cancel="deviceDetectNormal = false"
        title="检测结果"
      >
        <div class="detect-result">
          <i class="iconfont iconright-f"></i>
          <div class="right">
            <span class="title">考试设备及网络正常</span>
            <span class="content">可以正常进入考试系统</span>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showProblem"
        @dialog-cancel="showProblem = false"
        :show_close="false"
        title="设备检测常见问题"
        width="800"
      >
        <div class="problem-container">
          <h2 style="text-align: center;">常见问题回答</h2>
          <h2>电脑摄像头问题</h2>
          <p style="font-weight:bold"><span style="font-weight:bold;font-size:18px;color:#cb2a1d; ">问：</span>电脑摄像头硬件或驱动有故障，怎么办？</p>
          <p><span style="font-weight:bold;font-size:18px;color:#5fa4f9; ">答：</span>如果驱动故障，请从官网下载最新版驱动，安装后重启电脑。如果是硬件故障，请更换电脑摄像头，或更换其他的电脑设备。</p>
          <p style="font-weight:bold"><span style="font-weight:bold;font-size:18px;color:#cb2a1d; ">问：</span>电脑摄像头无故障，但就是无法看到图像，怎么办？</p>
          <p><span style="font-weight:bold;font-size:18px;color:#5fa4f9; ">答：</span>通常有四种原因：</p>
          <p>
            1、浏览器将摄像头禁用。请查看浏览器地址栏右侧是否有打叉的摄像头图标。解决办法：点击谷歌浏览器右上角三个竖点--设置--隐私设置和安全性--网站设置--摄像头，授予权限，然后Ctrl+F5刷新即可；
          </p>
          <p>2、有些品牌电脑的摄像头会有个物理的开关按钮，例如联想电脑，请务必把开关键打开；</p>
          <p>3、其他浏览器或应用程序占用了摄像头，请重启电脑即可解决；</p>
          <p>4、软件冲突。请关闭360魔法摄像头之类的工具软件，重启电脑即可解决；</p>
          <p>Tips：1、通过重启电脑基本都能解决问题；2、如果仍然有故障，请务必更换其他的电脑设备。</p>
          <p style="font-weight:bold">
            <span style="font-weight:bold;font-size:18px;color:#cb2a1d; ">问：</span>电脑摄像头显示后置画面，如何切换到前置画面？</p>
            <div><span style="font-weight:bold;font-size:18px;color:#5fa4f9; ">答：</span>可进行如下尝试：
  <p>1、点击谷歌浏览器右上角三个点--进入设置--安全和隐私设置--网站设置--摄像头--页面最上方下拉选择另外一个摄像头，然后刷新页面即可看到前置画面；</p>
  <p>2、如果上述步骤操作后仍不行，可尝试如下操作，首先打开电脑的设置--设备--蓝牙和其他设备--设备和打印机，弹出窗口，找到前置摄像头，右键选择属性，将该摄像头设置为默认摄像头，如果到设备和打印机这步没自动弹出弹窗，请在空白处右键，选择设备管理器，进入后找到摄像头；</p>
  <p>3、如果上述操作后仍不行，请更换其他的电脑设备。</p></div>
          <p style="font-weight:bold">
            <span style="font-weight:bold;font-size:18px;color:#cb2a1d; ">问：</span>电脑摄像头对准哪里算是合规？</p>
            <p><span style="font-weight:bold;font-size:18px;color:#5fa4f9; ">答：</span>请调整摄像头角度，将头像放置在画面中间位置，能清晰看清脸部，确保在整个答题过程中脸部正对摄像头。背景画面内不要有强光源，如有强光照射窗户，请拉上窗帘。
          </p>
          <p style="font-weight:bold">
            <span style="font-weight:bold;font-size:18px;color:#cb2a1d; ">问：</span>还有其他问题，有技术支持电话吗？</p>
            <p><span style="font-weight:bold;font-size:18px;color:#5fa4f9; ">答：</span>电脑摄像头正常开启对考试非常重要，为了保证考试的公平性，考官会全程视频监控考生作答的情况，因此请务必保证摄像头可以正常使用。如果还有其他技术问题，<span style="font-weight:bold">请致电：010-86623456</span>，技术人员将为您解答。
          </p>
          <h2>麦克风&扬声器问题</h2>
          <p style="font-weight:bold">
            <span style="font-weight:bold;font-size:18px;color:#cb2a1d; ">问：</span>电脑是台式机，没有麦克风和扬声器设备，怎么办？
            </p>
            <p><span style="font-weight:bold;font-size:18px;color:#5fa4f9; ">答：</span>建议自备外接的电脑麦克风和扬声器设备，确保可以正常使用。如果确实没有这两个设备，则可以忽略，确保电脑摄像头正常使用即可，对考试成绩没有影响。
          </p>
          <p style="font-weight:bold">
            <span style="font-weight:bold;font-size:18px;color:#cb2a1d; ">问：</span>电脑麦克风和扬声器设备故障，怎么办？
            </p>
            <p><span style="font-weight:bold;font-size:18px;color:#5fa4f9; ">答：</span>请在电脑设置里找到此设备，通过更新驱动或重启电脑来解决故障。如果故障无法解决，则可以忽略，确保电脑摄像头正常使用即可，对考试成绩没有影响。
          </p>
          <h2>网络问题</h2>
          <p style="font-weight:bold">
            <span style="font-weight:bold;font-size:18px;color:#cb2a1d; ">问：</span>系统检测网络较慢，比较卡顿，怎么办？
            </p>
            <div><span style="font-weight:bold;font-size:18px;color:#5fa4f9; ">答：</span>建议两种方式解决：
  <p>1、更换其他Wi-Fi信号强的网络，确保网络畅通；</p>
  <p>2、使用4G手机分享Wifi热点上网。通过电脑的无线设备连接手机的Wifi热点上网，正常的网速都比较快。</p></div>
      </div>
      </GlobalDialog>
      <div class="container">
        <div class="title">在线考试 设备检测</div>
        <div class="subtitle">
          为保证在线考试顺利进行，请按系统提示进行设备检测
        </div>
        <span class="problem" @click="showProblem = true">
          <i class="iconfont iconbangzhu"></i>
          检测有故障？查看解决办法！</span>
        <div class="content">
          <device-detect />
        </div>
      </div>
      <simple-footer />
    </div>
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import SimpleFooter from '@/components/SimpleFooter'
import GlobalDialog from '@/components/GlobalDialog'
import deviceDetect from '@/components/deviceDetect/index.vue'

export default {
  name: 'DeviceDetection',
  components: {
    SimpleHeader,
    SimpleFooter,
    GlobalDialog,
    deviceDetect
  },
  data() {
    return {
      deviceDetectNormal: false,
      showProblem: false,
      isMobile: true
    }
  },
  mounted() {
    this.testIsMobile()
  },
  methods: {
    testIsMobile() {
      this.isMobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      console.log(this.isMobile)
    }
  }
}
</script>

<style>
.mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;
  .iconfont {
    font-size: 120px;
    color: #f35a5a;
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #f35a5a;
  }
  .content {
    font-size: 14px;
    color: #999;
    margin-top: 20px;
  }
}
</style>

<style lang="scss" scoped>
.detect {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .problem-container {
    line-height: 1.8;
    max-height: 400px;
    overflow-y: auto;
  }
  .detect-result {
    display: flex;
    justify-content: center;
    align-items: center;
    .icontishi {
      font-size: 89px;
      color: #cb2a1d;
    }
    .iconright-f {
      font-size: 89px;
      color: #cb2a1d;
    }
    .right {
      margin-left: 20px;
      .title {
        display: block;
        font-size: 18px;
        margin-bottom: 20px;
      }
      .content {
        display: block;
      }
    }
  }
}
.container {
  flex: 1;
  width: 960px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  .problem {
    color:#cb2a1d;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 12px;
  }
  .submit {
    position: absolute;
    right: 20px;
    top: 60px;
  }
  .title {
    font-size: 28px;
    margin-bottom: 20px;
    color: #1e1e1e;
  }
  .subtitle {
    color: #717171;
    font-size: 14px;
    margin-bottom: 12px;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 500px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
</style>
